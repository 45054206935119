import "../../../styles/footer.css";
import api from "../../../services/api";
import { useMemo, useState } from "react";

export default function Footer() {
  const [footer, setFooter] = useState([]);

  useMemo(() => {
    api
      .get(`/footer`)
      .then((res) => {
        if (res.data.err !== true) {
          setFooter(res.data.res[0]);
        }
      })
      .catch((err) => {
        console.log("Tentando novamente..." + err);
        const res = api.get(`/footer`);
        if (res.status !== 200) {
          return window.location.reload();
        }
        setFooter(res.data.res[0]);
      });
  }, []);

  return (
    <section className="main-footer" id="ancoraRodape">
      <div className="footer">
        <div className="footer-div">
          <div className="div-list">
            <ul className="list">
              <h2>Atendimento</h2>
              <li>
                <img
                  src="/icons/Atendimento.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.HORARIO_ESIC != undefined && footer.HORARIO_ESIC}
              </li>

              <li>
                <img
                  src="/icons/envelope.svg"
                  alt="logo email"
                  className="svg-footer img-aten"
                />
                {footer.SITE_EMAIL_FALECOMNOSCO}
              </li>
              <li>
                <img
                  src="/icons/telephone.svg"
                  alt="logo contato"
                  className="svg-footer img-aten"
                />
                {footer.TELEFONE_EESIC}
              </li>
            </ul>

            <ul className="list">
              <h2>
                Localização -
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/CTpUXyXDqD2fMsNC8"
                  rel="noreferrer"
                >
                  Localize
                </a>
              </h2>
              <li>
                <img
                  src="/icons/geo-alt.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.ENDERECO}
              </li>
              <li>
                <img
                  src="/icons/MapaB.svg"
                  alt="logo MAPA"
                  className="svg-footer"
                />
                {footer.CIDADE}-{footer.UF} - CEP: {footer.CEP}
              </li>
              <li>
                <img
                  src="/icons/layers.svg"
                  alt="logo CNPJ"
                  className="svg-footer"
                />
                CNPJ: {footer.CNPJ}
              </li>
            </ul>

            <ul className="list">
              <h2>
                E-SIC -
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/CTpUXyXDqD2fMsNC8"
                  rel="noreferrer"
                >
                  Localize
                </a>
              </h2>
              <li>
                <img
                  src="/icons/geo-alt.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.ENDERECO}
              </li>
              <li>
                <img
                  src="/icons/envelope.svg"
                  alt="logo email"
                  className="svg-footer"
                />
                {footer.EMAIL_ESIC}
              </li>
            </ul>

            <ul className="list">
              <h2>
                Ouvidoria -
                <a
                  target="_blank"
                  href="https://maps.app.goo.gl/CTpUXyXDqD2fMsNC8"
                  rel="noreferrer"
                >
                  Localize
                </a>
              </h2>
              <li>
                <img
                  src="/icons/geo-alt.svg"
                  alt="logo localização"
                  className="svg-footer"
                />
                {footer.ENDERECO}
              </li>
              <li>
                <img
                  src="/icons/envelope.svg"
                  alt="logo email"
                  className="svg-footer"
                />
                {footer.EMAIL_OUVIDORIA}
              </li>
            </ul>
          </div>

          <div className="card-social">
            <h3>SESSÕES 1º SEMESTRE</h3>
          </div>
          <div className="sessoes-list">
            <ul>
              <h4>Fevereiro 2024:</h4>
              <li> Dia 19, segunda-feira as 8h</li>
              <li> Dia 20, terça-feira as 8h</li>
              <li> Dia 26, segunda-feira as 8h</li>
              <li> Dia 27, terça-feira as 8h</li>
            </ul>

            <ul>
              <h4>Março 2024:</h4>
              <li>Dia 04, segunda-feira as 8h</li>
              <li>Dia 11, segunda-feira as 8h</li>
              <li>Dia 18, segunda-feira as 8h</li>
              <li>Dia 25, segunda-feira as 8h </li>
            </ul>
            <ul>
              <h4>Abril 2024:</h4>
              <li>Dia 08, segunda-feira as 8h</li>
              <li>Dia 15, segunda-feira as 8h</li>
              <li>Dia 22, segunda-feira as 8h</li>
              <li>Dia 29, segunda-feira as 8h </li>
            </ul>
            <ul>
              <h4>Maio 2024:</h4>
              <li>Dia 06, segunda-feira as 8h</li>
              <li>Dia 13, segunda-feira as 8h</li>
              <li>Dia 21, segunda-feira as 8h</li>
              <li>Dia 27, segunda-feira as 8h </li>
            </ul>
            <ul>
              <h4>Junho 2024:</h4>
              <li>Dia 03, segunda-feira as 8h</li>
              <li>Dia 10, segunda-feira as 8h</li>
              <li>Dia 17, segunda-feira as 8h</li>
              <li>Dia 24, segunda-feira as 8h </li>
            </ul>
          </div>

          <div className="card-social">
            {footer.LINK_WHATSAPP != undefined &&
              footer.LINK_WHATSAPP != "" && (
                <a
                  href={footer.LINK_WHATSAPP}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/whatsapp.svg"
                    alt="logo WhatsApp"
                    className="svg-footer-social"
                    title="WhatsApp"
                  />
                  WhatsApp
                </a>
              )}

            {footer.LINK_INSTAGRAM != undefined &&
              footer.LINK_INSTAGRAM != "" && (
                <a
                  href={footer.LINK_INSTAGRAM}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/instagramWhite.svg"
                    alt="logo Instagram"
                    className="svg-footer-social"
                    title="Instagram"
                  />
                  Instagram
                </a>
              )}

            {footer.LINK_FACEBOOK != undefined &&
              footer.LINK_FACEBOOK != "" && (
                <a
                  href={footer.LINK_FACEBOOK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-social"
                >
                  <img
                    src="/icons/facebookWhite.svg"
                    alt="logo facebook"
                    className="svg-footer-social"
                    title="facebook"
                  />
                  Facebook
                </a>
              )}
            <a
              href="https://youtube.com/@camaraaltoparnaiba5834?si=uokBdv4op_3iYMrY"
              target="_blank"
              rel="noopener noreferrer"
              className="link-social"
            >
              <img
                src="/icons/youtube.svg"
                alt="logo youtube"
                className="svg-footer-social"
                title="youtube"
              />
              Youtube
            </a>
          </div>

          <div className="link-end-footer">
            <p>
              <a
                href="https://www.instagram.com/workcenter.consultoria"
                target="_blank"
                rel="noopener noreferrer"
              >
                Workcenter {"  "}
              </a>
              &copy; {new Date().getFullYear()}. E todos os direitos reservados.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
